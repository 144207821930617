import React from "react";
import styled from "styled-components";

function App() {
  return (
    <>
      <PageContainer>
        <ArticleContainer>
          <Title>Understanding Chat GPT: A Powerful Language Model</Title>
          <ImageContainer>
            <Image
              src={
                "https://images.unsplash.com/photo-1679083216051-aa510a1a2c0e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80"
              }
              alt="Weight loss scrabble word on plate"
            />
          </ImageContainer>
          <Subtitle>Introduction:</Subtitle>
          <Paragraph>
            Chat GPT is an advanced language model developed by OpenAI. It
            belongs to the GPT (Generative Pre-trained Transformer) family of
            models and is designed specifically for generating human-like text
            responses in conversational settings. Chat GPT is trained on vast
            amounts of text data from diverse sources, enabling it to understand
            and generate coherent and contextually relevant responses to user
            inputs.
          </Paragraph>
          <Subtitle>How Chat GPT Works:</Subtitle>
          <Paragraph>
            At its core, Chat GPT is based on a deep learning architecture known
            as a transformer. Transformers excel at capturing long-range
            dependencies in language by processing input sequences as a whole,
            rather than relying solely on sequential processing. This allows
            Chat GPT to understand and generate responses that take into account
            the context of the conversation.
          </Paragraph>
          <Subtitle>Applications of Chat GPT:</Subtitle>
          <Paragraph>
            Chat GPT has a wide range of applications across various industries
            and domains. Some common use cases include:
          </Paragraph>
          <List>
            <ListItem>
              Customer Support: Chat GPT can be used to automate customer
              support by providing instant responses to common queries and
              issues. It can help answer frequently asked questions,
              troubleshoot problems, and provide personalized recommendations.
            </ListItem>
            <ListItem>
              Virtual Assistants: Chat GPT can serve as the backbone of virtual
              assistants, powering their conversational abilities. It can
              understand user commands, perform tasks, retrieve information, and
              engage in natural language conversations.
            </ListItem>
            <ListItem>
              Content Generation: Chat GPT can assist in generating content for
              blogs, articles, social media posts, and more. It can provide
              creative suggestions, help with brainstorming ideas, and even
              draft entire pieces of text.
            </ListItem>
            <ListItem>
              Language Tutoring: Chat GPT can simulate conversations with
              language learners, providing them with practice opportunities and
              personalized feedback. It can engage in dialogue, answer
              questions, and offer language learning resources.
            </ListItem>
          </List>
          <Subtitle>Benefits and Considerations:</Subtitle>
          <Paragraph>
            Chat GPT offers several benefits, but it's important to consider
            certain factors when using it:
          </Paragraph>
          <List>
            <ListItem>
              Language Fluency: Chat GPT excels at generating fluent and
              coherent text, making interactions feel more human-like and
              natural.
            </ListItem>
            <ListItem>
              Contextual Understanding: Chat GPT can grasp context from previous
              messages, allowing it to provide relevant responses that align
              with the ongoing conversation.
            </ListItem>
            <ListItem>
              Limits of Knowledge: While Chat GPT has access to a vast amount of
              information, it may not always provide accurate or up-to-date
              answers. Care should be taken to verify and fact-check information
              from external sources.
            </ListItem>
            <ListItem>
              Biases and Ethical Considerations: Language models like Chat GPT
              can inadvertently reflect and amplify biases present in the
              training data. It's important to be aware of potential biases and
              use the model responsibly and inclusively.
            </ListItem>
          </List>
          <Conclusion>Conclusion:</Conclusion>
          <Paragraph>
            Chat GPT represents a significant advancement in natural language
            processing and has diverse applications across industries. By
            leveraging its capabilities, businesses can automate conversations,
            enhance customer experiences, and generate high-quality content.
            However, it's crucial to consider the limitations and ethical
            considerations associated with its usage to ensure responsible
            deployment.
          </Paragraph>
        </ArticleContainer>
      </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  min-height: 100vh;
`;

const ArticleContainer = styled.div`
  max-width: 800px;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Subtitle = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  margin-bottom: 20px;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Conclusion = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export default App;
