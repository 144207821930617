const articles = [
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1679083216051-aa510a1a2c0e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80",
    caption: "What is Chat GPT",
    link: "/chatgpt"
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1516589178581-6cd7833ae3b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bG92ZSUyMGxhbmd1YWdlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60",
    caption: "What are the 5 Love Languages",
    link: "/lovelanguage"
  },
  {
    id: 3,
    image:
      "https://images.unsplash.com/photo-1526925712774-2833a7ecd0d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1574&q=80",
    caption: "How to Screenshot on a Mac",
    link: "/screenshotmac"
  },
  {
    id: 4,
    image:
      "https://images.unsplash.com/photo-1572525557337-8d520106d8fe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    caption: "Who Escaped from Alcatraz",
    link: "/alcatrazescape"
  },
  {
    id: 5,
    image:
      "https://images.unsplash.com/photo-1604480132715-bd70038b74df?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1218&q=80",
    caption: "How to Lose Weight fast",
    link: "/loseweightfast"
  },
  {
    id: 6,
    image:
      "https://images.unsplash.com/photo-1597200381847-30ec200eeb9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80",
    caption: "Why is the Sky Blue",
    link: "/skyblue"
  },
  {
    id: 7,
    image:
      "https://images.unsplash.com/photo-1640826514546-7d2eab70a4e5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1172&q=80",
    caption: "What is Cryptocurrency and How It Works ",
    link: "/cryptocurrency"
  },
  {
    id: 8,
    image:
      "https://images.unsplash.com/photo-1477506350614-fcdc29a3b157?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    caption: "What are the Benefits and drawbacks of Intermittent fasting",
    link: "/intermittentfasting"
  }

  // Add more articles as needed
];
export default articles;
